/* navbar css */
.navbarContainer{
    height: 60px; 
   padding: 0px 25px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    background-color: rgb(255, 255, 255);
  }
  form{
    margin: 0;
  }
  .form-content-search{
    width: 205px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
   .form-content-search svg{
    color: rgb(131, 146, 165);
      margin-right: 10px;
  
   }
   svg:not(:root){
    overflow: hidden;
   }
   .form-content-search input{
    border-radius: 0px;
    border-width: 0px;
    padding: 0px;
    display: block;
    color: rgb(27, 46, 75);
    width: 100%;
    height: 38px;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
   }
   .navbarSide{
    display: flex;
    flex-wrap: wrap;
   }
  .navbarSide .nav-link{
    line-height: 1;
      padding: 0px;
      color: rgba(27, 46, 75, 0.75);
  }
  .navbarSide .nav-link svg{
    width: 20px;
    height: 20px;
  
  }
  .navbarSide .nav-link:not(:first-of-type) {
    margin-left: 10px;
  }