
.heading-backButton{
    /* background-color: red; */
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;

}
.heading-backButton h4{
    font-family: "IBM Plex Sans", sans-serif;
    line-height: 1.25;
    color: rgb(0, 23, 55);
    font-weight: 500;
    margin: 0px 0px 8px;
    letter-spacing: -0.5px;
    margin-bottom: 0px;
    font-size: 1.3125rem;

}
.heading-backButton button{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    user-select: none;
    border: 1px solid rgb(1, 104, 250);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: rgb(255, 255, 255);
    background-color: rgb(1, 104, 250);
    padding: 0.391rem 15px;
    border-radius: 0.25rem;
    margin-left: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 11px;
    padding-left: 15px;
    padding-right: 15px;

}
.hr {
    
    width: 100%;
    height: 1px;
    display: block;
    position: relative;
    margin-bottom: 0em;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);

   margin-top: 1%;

   
    
}

.purchaseOrderFormDiv{
   
    background-clip: border-box;
    /* border: 1px solid rgba(72, 94, 144, 0.16); */
    border-radius: 4px;
    padding: 4px 13px;
    margin-top: 3%;

}

.selectPurchaseOrderOptionsDiv{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: -13px;
}
.selectPurchaseOrderOptionsDiv .css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
padding: 12.5px 14px;
}
.selectPurchaseOrderOptionsDiv .css-1q964xs-MuiFormLabel-root-MuiInputLabel-root{
    color: rgba(27, 46, 75, 0.9);
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 16px;
    padding: 0px;
    height: 30px;
    letter-spacing: 0.5px;
    font-weight: 400;
    line-height: 12px;

}
.selectPurchaseOrderOptionsDiv .css-18pjc51-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: rgb(1, 104, 250);
}
.selectPurchaseOrderOptionsDiv .css-1cprn2g-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    color:  rgb(1, 104, 250);
}
.selectPurchaseOrderOptionsDiv .purchaseOrderDataForm{

}
 .purchaseOrderDataForm .formContainer{
    background-clip: border-box;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    /* border: 1px solid rgba(72, 94, 144, 0.16); */
    border-radius: 4px;
    padding: 2px 6px;
    margin-top: 2.5%
}
.formContainer .form{
    /* background-color: antiquewhite; */
    min-height: 350px;
    padding: 0px 0px 16px 0px;
    margin: 0px 0 0px 0;
    
    /* background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 4px; */
 
 
}
.purchaseOrderDataForm .headingSteeper{
    font-family: "IBM Plex Sans", sans-serif;
    line-height: 1.25;
    color: rgb(0, 23, 55);
    font-weight: 500;
    margin: 0px 0px 8px;
    letter-spacing: -0.5px;
    margin-bottom: 0px;
    font-size: 1.3125rem;
}
.purchaseOrderDataForm .steeperDiv{
    margin-top: 2%;
}