 
.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  position: fixed;
  height: 216%;
  outline-style: none;
}

.forentriesandaddbutton {
  margin-top: -5px;
  margin-bottom: -5px;
  display: flex;
  justify-content: space-between;
}

.bottole{
  display:flex;
  gap: 10px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #164194!important;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  transition: all 0.2s linear;
  border-radius: 4px!important;
}
.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #4285f4;
  border-radius: 0.125rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;  transition: all 0.2s linear;
  margin-top: -7px;
}
ul.pagination {
  gap: 15px;
  font-size: 12.5px;
  cursor: pointer;
  user-select: none;
}

/* logout */
.logouticon{
  font-size: 30px;
  font-weight: 900;
  color: rgb(249, 117, 117);
}

select.newselect {
  height: 28px;
  position: relative;
  top: -3px;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
}

.areaaaa{
  position: relative;
    top: -15px;
    height: 36px;
    border: 1px solid #c6c2c354;
}
  
.bootle {
  padding: 4px;
}

.logggin{
  background-color: white !important;
}
.text-primary {
  color: #4285f4 !important;
}
.index{
margin-bottom:3px!important;
margin-top: -5px !important;
}

.logo-brand{
  font-weight: 700 !important;
  font-size: 25px !important;
}

.indexeye{
  font-size: large;
  color: #4285f4;
  cursor: pointer !important;
}

.createadd{
  color: white;
}

.search{
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;
  display: flex;  
  align-items: center;
}

.searchBar{
  padding: 1rem 1rem 1rem 3.5rem;
 
}
.searchindex{
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #113f67;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.card-footer {
  bottom: 0;
  width: 100%;
  height: 40px;
  margin-top: 20px !important;
  padding: .75rem 1.25rem;
  background-color:  #e7eaf6 !important;
  border-top:  0px solid#e7eaf6(5, 5, 5, 0.125) !important; 
}

li:hover,.active{
  color: rgb(0, 0, 255);
}
.text-muted {
  color:#06335a;
}


.final-header{
  display: flex;
  align-items: center;
  justify-content:space-between;
}
